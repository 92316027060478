<template>
      <v-col cols="12" md="8" ref="col">
        <v-item>
          <v-card :color="state.black ? '#1e1e1e' : 'white'" dark height="500" title="oven">
            <v-app-bar
                    dark class="text-h6">{{ title }} (mL/h)
                  </v-app-bar>
            <GChart v-if="chartData.length > 1" class="cost-graph" type="AreaChart" :data="chartData"
                    :options="chartOptions" />
          </v-card>
        </v-item>
      </v-col>
  </template>
  
  <script>
  import { GChart } from 'vue-google-charts/legacy'
  import state from '../../../state'
  import { getCurrentInstance } from 'vue';
  export default {
      components: {GChart },
    data: () => ({
        chartData: [
      ['Time', 'Gasdata'],
      ['Pump', 1000],
      ['Lowlevel', 1170],
      ['Highlevel', 660],
      ['E stop', 1030]
    ],
    chartOptions: {
      title: '',
      series: {
        0: { color: '#970e76', areaOpacity: 0.85 },
        1: {color: '#dc6511'},        
        2: { color: '#b70d7f'}
      },
      hAxis: {
        format: 'dd/MM HH:mm',
        textStyle:{color: '#7c7c7c'},
        gridlines: {
          units: {
              days: {format: ['MMM dd']},
              hours: {format: ['HH:mm', 'ha']},
              color: '#434242'
            },
        },
        
      },
      vAxis: {
        textStyle:{color: '#7c7c7c'},        
        gridlines: {
        },
      },
      legendTextStyle: { color: '#7c7c7c' },
      titleTextStyle: { color: '#7c7c7c' },
      color: '#FFF',
      backgroundColor: {fill:'transparent'},
      chartArea: { left: 40, top: 20, width: "88%", height: "60%" },
      legend: {position: 'bottom'},
      width: window.innerWidth / 1.35,
      height:  window.innerHeight / 2.2
    },
    loaded: false,
    state,
  }),
  props: {
    title: String,
    data: Array,
    secondPump: Boolean,
  },
  watch: {
    'state.chemicalData' (val) {
      this.loadGraph ()
    }
  },
  mounted() {      
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
    this.chartOptions.width = this.$refs.col.clientWidth
    this.chartOptions.height = this.$refs.col.clientHeight - 40
  },

  methods: {
    onResize() {
      this.chartOptions.width = this.$refs.col.clientWidth
      this.chartOptions.height = this.$refs.col.clientHeight - 40
    },
    loadGraph () {
      this.loaded = false;
      let tempData = []
      this.chartData = []
      tempData[0] = ['Time', 'overal']
      let comp = 1
      console.log(this.data)
      if (this.data[this.data.length -1 ].dosing) {
        this.data = this.data.sort((a, b) => (a.dosing.timeStamp > b.dosing.timeStamp) ? 1 : -1)
      }

    for (let i = 0; i<this.data.length; i++) {
      if (this.data[i].dosing) {
      if (this.data[i].dosing.timeStamp) {
        let formatTimestamp = new Date(this.data[i].dosing.timeStamp)
      formatTimestamp.setSeconds(0,0)
      formatTimestamp = formatTimestamp.toLocaleString([], {day: '2-digit', month: '2-digit', hour: '2-digit', minute:'2-digit'});
        let dosingValue = this.data[i].dosing.amount1
        if (this.secondPump) {
          dosingValue = this.data[i].dosing.amount2
        }
          tempData[i+1] = [formatTimestamp,  dosingValue *  0.93 * 12]
      } else {
        comp += 1
      }
      if ((i+comp) == this.data.length -1) {
            this.loaded = true;
        }
      }
      this.chartData = tempData
      const instance = getCurrentInstance();
    }
    }
  },
  created() {
    this.loadGraph ()
        
    },
}
  </script>
  <style>
  .pie-chart {
    width: auto;
      margin: auto;
  }
  .icon-play-circle{
    font-size: 100px;
      position: absolute;
      top: 75%;
      left: 45%;
      opacity: 1;
      color: blue;
  }
  .margin-bottom{
    margin-bottom: 100px;
  }
  .blurred {
    background-color: #eeeeee;
      flex: inherit;
      width: 85%;
      left: 100px;
      height: 30%;
      border-radius: 20px;
      opacity: 0.1;
      top: 65%;
      flex-direction: column;
      float: inline-end;
      position: absolute;
  }
  </style>