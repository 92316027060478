<template>
    <div id="app" class="flex justify-content-center">
      <sidebar @open-modal="showModal = true" />
      <SelectBar/>
        <v-card v-if="state.userData.username != 'demo_protech'"
      class="mx-auto pa-12 pb-8 mt-15"
      elevation="8"
      max-width="448"
      width="300"
      rounded="lg"
      text="Settings"
      margin-top="100"
    >
    
    <div class="text-h6 ">
                Prices
              </div>
        <v-text-field
        density="compact"
        placeholder="Electric price"
        label="Electric price"
        prepend-inner-icon="mdi mdi-lightning-bolt"
        variant="outlined"
        v-model="state.Settings.electric_price"
      ></v-text-field>
      <v-text-field
        density="compact"
        placeholder="Gass price"
        label="Gass price"
        prepend-inner-icon="mdi mdi-fire"
        variant="outlined"
        v-model="state.Settings.gas_price"
      ></v-text-field>
      <v-text-field
        density="compact"
        placeholder="Bath 1 chemical"
        prepend-inner-icon="mdi mdi-flask"
        variant="outlined"
        label="Chemical bath 1"
        v-model="state.Settings.chemical_price"
      ></v-text-field>
      <v-btn
        block
        class="mb-8"
        color="#97c11f"
        size="large"
        variant="tonal"
        @click="save"
      >
        Save
      </v-btn>
      </v-card>

      <v-card v-if="state.Settings"
      class="mx-auto pa-12 pb-8 mt-5"
      elevation="8"
      max-width="448"
      width="300"
      rounded="lg"
      text="Settings"
      margin-top="100"
    >
    
    <div class="text-h6 mb-4">
                General settings
              </div>
              <v-switch
          color="black"
          v-model="state.black"
          label="Dark mode"
        ></v-switch>


      </v-card>

      <DownloadCSV/>
    </div>
  </template>
    
  <script>
  import Sidebar from '../components/Sidebar.vue'
  import api from '../api'
  import state from '../state'
  import dataHandler  from '../script/Calculations/dataHandler'
  import Settings from '../script/Settings'
  import DownloadCSV from '../components/CSV/DownloadCSV.vue'
  import SelectBar from '../components/SelectBar.vue'
  export default {
    components: { Sidebar, SelectBar, DownloadCSV},
    data: () => ({
      selectItem: 0,
      select: 'Today',
      dates: [],
      items: ['Today', 'Week', 'Month', 'Last year'],
      selectedProject: 'SAZ',
      allUsers: null,
      currentTime: 1,
      dHandler: new dataHandler(),
      Settings: new Settings(),
      selectedUser: 'OP',
      api,
      userD: {
        name: ''
      },
      loaded: false,
      loadedTest: false,
      state,
    }),
    watch: {
    'state.date.select' (val) {
      this.dHandler.getData()
      },
    'state.Settings' (val) {

    }
    },
    mounted() {
      this.state.currentPage = "Settings"
      //this.getApiCall();
      this.Settings.getSettings()
      this.loadedTest = true
    },
    methods: {
        save () {
            this.state.Settings.ID = this.state.selectedID
            localStorage.setItem('black', this.black)
            this.Settings.saveSettings()
        },
    } 
  }
  </script>
    
  <style scoped>
  .border {
    margin-left: 12px;
    margin-right: 12px;
    border-radius: 10%;
    text-decoration: none;
  }
  
  .space {
    margin-left: -100px;
    margin-right: -100px;
  }
  
  .select {
    max-width: 200px;
    max-height: 500px;
    margin-left: 20px;
    margin-top: 20px;
    font-size: 20px;
  }
  
  .top {
    margin-top: 80px;
  }
  
  .v-list-item-group .v-list-item--active {
    color: white !important;
  }
  
  .center-title {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(203, 203, 203);
  }
  
  .center-title2 {
    align-self: center;
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
    color: rgb(131, 127, 127);
  }
  
  .cost-graph {
    margin-left:20px;
  }
  
  .pie-chart {
    width: auto;
      margin: auto;
  }
  </style>
    