<template>
  <div id="app" class="flex justify-content-center">
    <sidebar @open-modal="showModal = true" />
    <v-item-group v-if="showDashboard">
      <v-toolbar elevation="">
        <v-btn icon class="hidden-xs-only" @click="showDashboard = false">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
        <v-text-field class="mt-7" v-model="currentObject.name" solo elevation="0"></v-text-field>
        <v-spacer></v-spacer>
        <v-divider color="rgba(0,0,0,0.6)" vertical></v-divider>
        <v-btn class="white--text mr-5 ml-5 text-left" prepend-icon="mdi-account" variant="success" @click="dialog = true" color="primary">
          <v-icon class="mr-3" color="white" left>fa fa-plus</v-icon>
          <h4 class="mr-10 mt-2 text-caption" color="white">Add new element</h4>
        </v-btn>
        <v-divider color="rgba(0,0,0,0.6)" vertical></v-divider>
        <v-switch class="ml-5 mr-15 mt-5" v-model="edit" label="Edit in page">Edit</v-switch>
        <v-divider color="rgba(0,0,0,0.6)" vertical></v-divider>
        <v-btn class="white--text mr-5 ml-5 text-left" prepend-icon="mdi-account" variant="tonal" @click="saveLab" rounded color="#97c11f">
          <v-icon class="mr-3" color="white" left>fa fa-file</v-icon>
          <h4 class="mr-5 mt-2 text-h6" color="white">Save dashboard</h4>
        </v-btn>
      </v-toolbar>
      <AddComponentModal v-model="dialog" @emit-result="emited" @newItem="newItem" />
      <v-divider :thickness="10" class="border-opacity-100"></v-divider>
      <v-row v-if="isLoaded" justify="center" class="ml-15 mr-15">
        <v-col v-for="item in currentObject.displayList" :md="item.size" :key="item.key">
          <v-row justify="center">
            <HoverAdd @clicked="addAtIndex('before', item.key)" v-if="edit" />
            <v-col :md="edit ? '8' : '12'">
              <v-card>
                <v-app-bar height="40">
                  <h5>{{ item.title }}</h5>
                  <v-spacer></v-spacer>
                  <v-btn @click="deleteItem(item.key)" icon>
                    <v-icon small>fa-trash</v-icon>
                  </v-btn>
                </v-app-bar>
                <LabsGraph v-if="item.type == 'Graph'" :dataObj="item" />
                <Doughnut v-if="item.type == 'Doughnut'" :dataObj="item" />
                <v-card-text v-if="item.type == 'Note'">{{ item.description }}</v-card-text>
              </v-card>
            </v-col>
            <HoverAdd v-if="edit" @clicked="addAtIndex('after', item.key)" />
          </v-row>
        </v-col>
      </v-row>
    </v-item-group>
    <v-item-group v-else>
      <v-row justify="begin" class="mt-15 ml-15">
        <v-col v-for="item in allObjects" :key="item.key" md="2" @click="openLab(item.key)">
          <v-card height="400" hover variant="outlined" outlined>
            <v-app-bar>
              <h4>{{ item.name }}</h4>
            </v-app-bar>
            <v-card-text>{{ item.description }}</v-card-text>
          </v-card>
        </v-col>
        <v-col md="2">
          <v-card height="400" hover @click="openCreation = true" outlined>
            <v-row justify="center">
              <v-col md="2" align-self="center">
                <v-btn height="370" hover @click="openCreation = true" icon>
                  <v-icon color="primary" size="350">fa fa-plus</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
      <CreateNewLab v-model="openCreation" @createLab="createLab" />
    </v-item-group>
    <SelectBar />
  </div>
</template>

<script>
import Sidebar from '../components/Sidebar.vue';
import api from '../api';
import axios from 'axios';
import state from '../state';
import SelectBar from '../components/SelectBar.vue';
import tracking from '../script/tracking/tracking';
import LabsGraph from '../components/Labs/LabsGraph.vue';
import HoverAdd from '../components/Labs/HoverAdd.vue';
import AddComponentModal from '../components/Labs/AddComponentModal';
import Doughnut from '../components/Labs/LabsDoughnut.vue';
import CreateNewLab from '../components/Labs/CreateNewLab.vue';

export default {
  components: {
    Sidebar,
    SelectBar,
    LabsGraph,
    AddComponentModal,
    HoverAdd,
    CreateNewLab,
    Doughnut,
  },
  data() {
    return {
      trackingjs: new tracking(),
      selectedUser: 'OP',
      allObjects: [],
      currentObject: {},
      api,
      state,
      openCreation: false,
      name: 'empty',
      displayList: [],
      cnt: 0,
      dialog: false,
      showDashboard: false,
      showDialog: false,
      addAtIndexPos: '',
      isAddAtIndex: false,
      edit: false,
      isLoaded: true,
      index: 0,
    };
  },
  mounted() {
    
    state.currentPage = 'Indufinish labs';
    this.state.format = 'Day';
    
    let user = state.selectedID;
    if (this.state.userData.rights.length > 1) {
      user = this.state.userData.username;
    }
    const data = {
      user: '*',
      ID: user,
    };
    axios.post(api.getLabs, data).then((res) => {
      this.allObjects = res.data;
    });
  },
  methods: {
    deleteItem(key) {
      this.isLoaded = false;
      const destination = this.currentObject.displayList.findIndex((item) => item.key === key);
      this.currentObject.displayList.splice(destination, 1);
      this.isLoaded = true;
    },
    saveLab() {
      axios.post(api.saveLabs, this.currentObject).then(() => {
        console.log('done');
      });
    },
    openLab(key) {
      this.currentObject = this.allObjects.find((item) => item.key === key) || {};
      this.showDashboard = true;
    },
    createLab(val) {
      val.key = this.allObjects.length + 1;
      val.displayList = [];
      val.ID = this.state.userData.rights.length > 1 ? this.state.userData.username : this.state.userData.rights[0];
      this.allObjects.push(val);
      this.currentObject = val;
      this.openCreation = false;
      this.showDashboard = true;
    },
    addAtIndex(pos, item) {
      this.isAddAtIndex = true;
      this.addAtIndexPos = `${item} ${pos}`;
      this.dialog = true;
    },
    newItem(item) {
      item.key = this.index;
      if (this.isAddAtIndex) {
        const target = this.addAtIndexPos.split(' ')[0];
        const position = this.addAtIndexPos.split(' ')[1];
        const location = this.currentObject.displayList.findIndex((el) => el.key === target) + (position === 'after' ? 1 : 0);
        this.currentObject.displayList.splice(location, 0, item);
        this.isAddAtIndex = false;
      } else {
        this.currentObject.displayList.push(item);
      }
      this.index += 1;
    },
    emited(res) {
      console.log(res);
    },
  },
};
</script>

  <style>
  .addCard{
    color: rgb(255, 0, 0);
  }
</style>
