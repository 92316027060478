<template>
    <div v-if="state.chain_available">
      <v-card class="mr-5 ml-5 mt-15 rounded-tl-xl rounded-br-xl" :style="state.black ? {background: '#363636'} : {background: '212121'} ">
      <v-app-bar
        dark
        @click="tab=!tab"
        class="rounded-tl-xl rounded-br-xl"
      >

      <v-badge content="Base" color="black" inline>
        <h3>Chain</h3>
      </v-badge>
        <v-spacer></v-spacer>
        <v-icon v-if="tab" right>
          fa fa-angle-down
        </v-icon>
        <v-icon v-else right>
          fa fa-angle-up
        </v-icon>
      </v-app-bar>
      <v-expand-transition v-if="tab">
        <v-row class="ml-5 mr-5">
          <v-col md="2">
            <v-item  class="mb-5">
                <v-card :color="'black'" round class="d-flex align-center bg-secondary rounded-tl-xl" dark>
                  <v-row>
                    <v-col cols="12" sm="10">
                      <v-list-item three-line class="mt-10">
                        <v-list-item-content>
                          <div class="mb-6">

                            <v-icon x-large :color="'white'">mdi mdi-cog-outline</v-icon>

                          </div>
                          <v-list-item-subtitle :class="'h2 white--text'">Total minutes run (chain)</v-list-item-subtitle>
                          <v-list-item-title class="h3 headline mb-1" :class="'white--text'">
                            <strong v-if="state.loadedAll">{{ Math.round(state.totalChain) }} Minutes</strong>
                          </v-list-item-title>

                        </v-list-item-content>
                      </v-list-item>
                    </v-col>
                  </v-row>

                </v-card>
              </v-item>
          </v-col>
          <v-col md="10" v-if="state.loadedAll"><LabsGraph v-if="state.loadedAll" :dataObj="state.chain_graph.chartData"/></v-col>
          <v-row v-show="tab"  justify="center" class="align-top ml-4">
            <v-col md="2"></v-col>
              <v-col md="10" v-if="state.loadedAll"><LabsGraph v-if="state.loadedAll" :dataObj="state.chain_graph_Linear.chartData"/></v-col>
                <v-row>
        </v-row>



        </v-row>
        </v-row>
      </v-expand-transition>
        </v-card>
        
    </div>
 </template>
 <script>
 import state from '../../state'
 import { getCurrentInstance } from 'vue';
 import LabsGraph from '../Labs/LabsGraph.vue'
 export default {
   components: {LabsGraph},
   data: () => ({
     currentTime: 1,
     state,
     tab: true,
   }),
 
   mounted() {
   },
 }
 </script>